import React, { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { IoMdClose } from "react-icons/io"

const SignaturePopup = (props) => {

    let sigCanvas = useRef({})

    const clearSigCanvas = () => {
        sigCanvas.clear()
    }

    const saveSigCanvas = () => {
        // Créer un canvas temporaire pour redimensionner la signature
        const canvas = document.createElement('canvas');
        canvas.width = 300;
        canvas.height = 200;
        const ctx = canvas.getContext('2d');

        // Dessiner l'image de la signature redimensionnée dans le nouveau canvas
        ctx.drawImage(sigCanvas.getTrimmedCanvas(), 0, canvas.height / 2 - 100 / 2, 150, 100);
        // ctx.drawImage(sigCanvas.getTrimmedCanvas(), canvas.width / 2 - 150 / 2, canvas.height / 2 - 100 / 2, 150, 100);

        // Convertir le canvas redimensionné en Base64 (sans le préfixe data:image)
        const resizedBase64 = canvas.toDataURL('image/PNG').split(',')[1];

        // Sauvegarder dans le champ du formulaire
        props.setFieldValue(props.inputName, resizedBase64);
        props.close(); // Fermer la popup
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                {props.subtitle &&
                    <div className='p-3'>{props.subtitle}</div>
                }
                <div className="popup-body">
                    <div className="signature-container">
                        <SignatureCanvas
                            ref={(ref) => { sigCanvas = ref }}
                            penColor='black'
                            canvasProps={{ width: 350, height: 200, className: 'sigCanvas' }}
                        />
                    </div>
                    <div className="sig-button-container mt-4">
                        <button type="button" className="btn btn-danger" onClick={clearSigCanvas}>
                            Effacer
                        </button>
                        <button type="button" className="btn cbtn-success ml-4" onClick={saveSigCanvas}>
                            Enregistrer
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default SignaturePopup