import axios from "axios"
import history from "../helpers/history"
import { isNullOrUndefined } from "../helpers/utils"
import apilink from "./../constants/apilink"

const getAllAdherent = (search = "", actif = true, activite = "all", option = 2) => {
    return axios.get(apilink + "/adherent?search=" + search + "&actif=" + actif + "&activite=" + activite + "&option=" + option)
        .then(response => response.data)
}

const loginAdherent = (user_adherent, user_passe) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_adherent, user_passe })
    }

    // JSON parse car specialement pour le login un texte est retourne
    return fetch(apilink + "/adherent/login", requestOptions)
        .then(handleResponse)
        .then(adherent => {
            // Enregistre les données de l'utilisateur ainsi que son token afin d'etre authentifier a chaque chargement
            localStorage.setItem("adherent", adherent)
            return JSON.parse(adherent)
        })

}

const getAdherent = (adherent) => {
    return axios.get(apilink + "/adherent/" + adherent)
        .then(response => response.data)
}

const getAdherentByIdentification = (identification) => {
    return axios.get(apilink + "/adherent/id/" + identification)
        .then(response => response.data)
}

const getAllIntervenant = (adherent, qualification = true) => {
    return axios.get(apilink + "/adherent/intervenants/" + adherent + "?qualif=" + qualification)
        .then(response => response.data)
}

const getIntervenant = (numero_adherent, numero_intervenant) => {
    const request = {
        numero_adherent: numero_adherent,
        numero_intervenant: numero_intervenant
    }

    return axios.post(apilink + "/siege/contact", request)
        .then(response => response.data)
}

const getIntervenantQualif = (numero_adherent, numero_intervenant) => {
    return axios.get(apilink + "/siege/adherent/" + numero_adherent + "/" + numero_intervenant)
        .then(response => response.data)
}

const getSupervisionDate = (presaisie) => {
    return axios.post(apilink + "/adherent/supervisions", presaisie)
        .then(response => response.data)
}

const getPreSaisieModel = (saisie) => {
    saisie = JSON.parse(saisie)
    return axios.post(apilink + "/adherent/presaisie", saisie)
        .then(response => response.data)
}

const initPointControle = (saisie) => {
    return axios.post(apilink + "/adherent/pointcontrole/load", saisie)
        .then(response => response.data)
}

const rechargerVidePointControle = (saisie) => {
    const value = {
        numero_adherent: saisie.adherent,
        numero_activite: saisie.activite,
        numero_intervenant: saisie.intervenant,
        numero_supervision: saisie.supervision === "" || !saisie.supervision ? 0 : parseInt(saisie.supervision),
        type_supervision: saisie.type_supervision,
        actif_type: 1,
        sans_historique: isNullOrUndefined(saisie.sans_historique) ? false : saisie.sans_historique,
    }
    process.env.NODE_ENV === "development" && console.log(value)
    return axios.post(apilink + "/adherent/pointcontrole/vide", value)
        .then(response => response.data)
}

const initSupervision = (saisie) => {
    return axios.post(apilink + "/adherent/general/load", saisie)
        .then(response => response.data)
}

const createGeneral = (general) => {
    return axios.post(apilink + "/adherent/general/new", general)
        .then(response => response.data)
}

const createPointControle = (presaisie, val) => {
    const general = presaisie
    const { pointcontrole } = val

    return axios.post(apilink + "/adherent/pointcontrole/new", { general, pointcontrole })
        .then(response => response.data)
}

const initLienRapport = (saisie) => {
    return axios.post(apilink + "/adherent/lienrapport/load", saisie)
        .then(response => response.data)
}
/**
 * 
 * @param {number} adherent 
 * @param {number} intervenant 
 * @param {number} activite 
 * @param {number} annee 
 * @returns Array
 */
const getAllNumeroRapport = (adherent, intervenant, activite, annee) => {
    const model = {
        numero_adherent: adherent,
        numero_intervenant: intervenant,
        activite: activite,
        annee: annee
    }

    return axios.post(apilink + "/adherent/lienrapport/numero", model)
        .then(response => response?.data)
}

const getAllTypeDocByActivite = (activite) => {
    return axios.get(apilink + "/adherent/typedoc/" + activite)
        .then(response => response.data)
}

const getAllDetailDocByTypeDoc = (typedoc) => {
    return axios.get(apilink + "/adherent/detaildoc/" + typedoc)
        .then(response => response.data)
}

const saveSupervision = (supervision) => {
    process.env.NODE_ENV === "development" && console.log(supervision)

    return axios.post(apilink + "/adherent/supervision/save", supervision)
        .then(response => response.data)
}

const saveSupervisionPartially = (supervision) => {
    process.env.NODE_ENV === "development" && console.log(supervision)

    return axios.post(apilink + "/adherent/supervision/presave", supervision)
        .then(response => response.data)
}

const updateSupervisionPartially = (supervision) => {
    process.env.NODE_ENV === "development" && console.log(supervision)

    return axios.put(apilink + "/adherent/supervision/presave", supervision)
        .then(response => response.data)
}

const updateSupervision = (supervision) => {
    process.env.NODE_ENV === "development" && console.log(supervision)

    return axios.put(apilink + "/adherent/supervision/presave", supervision)
        .then(response => response.data)
}

const getAllDocumentsQualites = (adherent = -1, intervenant = -1, activite = "all") => {
    return axios.get(apilink + "/adherent/documents?adh=" + adherent + "&int=" + intervenant + "&act=" + activite)
        .then(response => response.data)
}

const getTextes = (activite = "all", vision = false/*, abroges = false*/) => {
    return axios.get(apilink + "/adherent/textes?act=" + activite + "&vis=" + vision/* + "&act=" + activite*/)
        .then(response => response.data)
}

const getDocumentListeByNumeroDocument = (numero) => {
    return axios.get(apilink + "/adherent/documents/" + numero)
        .then(response => response.data)
}

const getDocumentListeByTypeAndDetailDoc = (type, detail) => {
    return axios.get(apilink + "/adherent/documents/" + type + "/" + detail)
        .then(response => response.data)
}

const getClients = (data) => {
    return axios.post(apilink + "/adherent/clients", data)
        .then(response => response.data)
}

const getClient = (data) => {
    return axios.post(apilink + "/adherent/client", data)
        .then(response => response.data)
}

const getStationnements = (data) => {
    return axios.post(apilink + "/adherent/stationnements", data)
        .then(response => response.data)
}

const getVehiculeStationnement = (data) => {
    return axios.post(apilink + "/adherent/vehicule-stationnement", data)
        .then(response => response.data)
}

const getInfos = (data) => {
    return axios.post(apilink + "/adherent/intervention/infos", data)
        .then(response => response.data)
}

const getInterventionTaximetre = (data) => {
    return axios.post(apilink + "/adherent/intervention/taximetre", data)
        .then(response => response.data)
}

const getInterventions = (data, pageNumber = 1, pageSize = 5) => {
    return axios.post(apilink + "/adherent/interventions?pageNumber=" + pageNumber + "&pageSize=" + pageSize, data)
        .then(response => response.data)
}

const getInterventionsTachy = (data, pageNumber = 1, pageSize = 5) => {
    return axios.post(apilink + "/adherent/interventions-tachy?pageNumber=" + pageNumber + "&pageSize=" + pageSize, data)
        .then(response => response.data)
}

const lectureIntervention = (data) => {
    return axios.post(apilink + "/adherent/intervention/lecture", data)
        .then(response => response.data)
}

const getModelesLog = (marque, modele) => {
    return axios.get(apilink + "/adherent/modeleslog/" + marque + "/" + modele)
        .then(response => response.data)
}

const getModeleLog = (marque, modele, logiciel) => {
    return axios.get(apilink + "/adherent/modeleslog/" + marque + "/" + modele + "/" + logiciel)
        .then(response => response.data)
}

const getTypeTousAppareils = () => {
    return axios.get(apilink + "/adherent/typeappareils")
        .then(response => response.data)
}

const getAllArreteNational = () => {
    return axios.get(apilink + "/adherent/arretes")
        .then(response => response.data)
}

const getAllArreteNationalDpt = (dept) => {
    return axios.get(apilink + "/adherent/arretes/dpt/" + dept)
        .then(response => response.data)
}

const getVehiculeTaxPhotos = (data) => {
    return axios.post(apilink + "/adherent/taxphotos", data)
        .then(response => response.data)
}

const getVehiculeTaxMateriel = (data) => {
    return axios.post(apilink + "/adherent/materiel", data)
        .then(response => response.data)
}

const getMarqueAppareil = (type) => {
    return axios.get(apilink + "/adherent/appareils/" + type)
        .then(response => response.data)
}

const getModeleAppareil = (type, marque) => {
    return axios.get(apilink + "/adherent/appareils/" + type + "/" + marque)
        .then(response => response.data)
}

const getModeleTousAppareils = (marque, modele) => {
    return axios.get(apilink + "/adherent/appareil/" + marque + "/" + modele)
        .then(response => response.data)
}

const getAllCouleur = () => {
    return axios.get(apilink + "/adherent/couleurs")
        .then(response => response.data)
}

const getAllMontageAutorise = (data) => {
    return axios.post(apilink + "/adherent/montages", data)
        .then(response => response.data)
        .catch(error => console.log(error))
}

const saveSaisiePartially = (saisie) => {
    return axios.put(apilink + "/adherent/intervention/saisie/presave", saisie)
        .then(response => response.data)
}

const saveSaisie = (saisie) => {
    process.env.NODE_ENV === "development" && console.log(saisie)

    return axios.put(apilink + "/adherent/intervention/saisie/save", saisie)
        .then(response => response.data)
}


const getAllDepartement = () => {
    return axios.get(apilink + "/adherent/departements")
        .then(response => response.data)
}

const getAidePneuAvant = (adherent) => {
    return axios.get(apilink + "/adherent/aides/pneu_av/" + adherent)
        .then(response => response.data)
}

const getAidePneuArriere = (adherent) => {
    return axios.get(apilink + "/adherent/aides/pneu_ar/" + adherent)
        .then(response => response.data)
}

const readDocument = (data) => {
    return axios.post(apilink + "/adherent/intervention/documents/read", data)
        .then(response => response.data)
}

const controlePose = (data, type) => {
    return axios.post(apilink + "/adherent/intervention/vignette/controle?type=" + type, data)
        .then(response => response.data)
}

/**
 * 
 * @param {Number} departement 
 * @returns 
 */
const getCodePostalsByClefDpt = (departement) => {
    return axios.get(apilink + "/adherent/codepostals/" + departement)
        .then(response => response.data)
}

const getAutorisationCouleurLum = (dept, cp) => {
    const data = {
        dept: dept,
        cp: cp
    }

    return axios.post(apilink + "/adherent/aut-couleur/", data)
        .then(response => response.data)
}

const getDepartement = (clef_dpt) => {
    return axios.get(apilink + "/adherent/departements/" + clef_dpt)
        .then(response => response.data)
}

const getVehiculeTaximetreVignettes = (data) => {
    return axios.post(apilink + "/adherent/intervention/taxvignette", data)
        .then(response => response.data)
}

const getIntervention = (data) => {
    return axios.post(apilink + "/adherent/intervention", data)
        .then(response => response.data)
}

const egalePrecedent2 = (data) => {
    return axios.post(apilink + "/adherent/intervention/precedent-2", data)
        .then(response => response.data)
}

const egalePrecedentAutres = (data) => {
    return axios.post(apilink + "/adherent/intervention/autres", data)
        .then(response => response.data)
}

const egalePrecedentPhotos = (data) => {
    return axios.post(apilink + "/adherent/egaleprec/taxphotos", data)
        .then(response => response.data)
}

const egalePrecedentTax = (data) => {
    return axios.post(apilink + "/adherent/intervention/tax", data)
        .then(response => response.data)
}

const egalePrecedentVignette = (data) => {
    return axios.post(apilink + "/adherent/intervention/vignette", data)
        .then(response => response.data)
}

const getManometre = (data) => {
    return axios.post(apilink + "/adherent/manometres", data)
        .then(response => response.data)
}

const getPlanning = (data) => {
    return axios.post(apilink + "/adherent/plannings", data)
        .then(response => response.data)
}

const getStockVignetteAdherent = (data) => {
    return axios.post(apilink + "/adherent/stock/vignette", data)
        .then(response => response.data)
}

const checkVignetteStockIntervention = (data) => {
    return axios.post(apilink + "/adherent/intervention/check-vignette", data)
        .then(response => response.data)
}

const getCodeTournant = () => {
    return axios.get(apilink + "/adherent/code-tournant")
        .then(response => response.data)
}

const verifyCodeDeblocage = (code_tournant, data) => {
    return axios.post(apilink + "/adherent/verify-code/" + code_tournant, data)
        .then(response => response.data)
}

const getFocusSupervision = (data) => {
    return axios.post(apilink + "/adherent/supervision/focus", data)
        .then(response => response.data)
}

const isVignetteUnique = (data) => {
    return axios.post(apilink + "/adherent/intervention/vignette-unique", data)
        .then(response => response.data)
}

const testReport = (data) => {
    return axios.get(apilink + "/adherent/report")
        .then(response => response.data)
}

const getInstrumentsDispos = (data) => {
    return axios.post(apilink + "/adherent/instruments-dispos", data)
        .then(response => response.data)
}

const getModeleTypeTax = (data) => {
    return axios.post(apilink + "/adherent/intervention/modele-type-tax", data)
        .then(response => response.data)
}

const getDetailFixation = (data) => {
    return axios.get(apilink + "/adherent/detail-fixation/" + data)
        .then(response => response.data)
}

const getNumTaxSerieObligatoire = (data) => {
    return axios.post(apilink + "/adherent/modelesTousAppareils/serie-obligatoire", data)
        .then(response => response.data)
}

const getDateEffectueeIntervention = (data) => {
    return axios.post(apilink + "/adherent/intervention/date-effectuee", data)
        .then(response => response.data)
}

const getHistoriqueAfficheur = (data) => {
    return axios.post(apilink + "/adherent/intervention/historique-afficheur", data)
        .then(response => response.data)
}

const getArbreTaximetre = (data) => {
    return axios.post(apilink + "/adherent/intervention/arbre-taximetre", data)
        .then(response => response.data)
}

const getArbreInterventions = (data) => {
    return axios.post(apilink + "/adherent/intervention/arbre-intervention", data)
        .then(response => response.data)
}

const getStartDate = () => {
    return axios.get(apilink + "/adherent/intervention/start-date")
        .then(response => response)
}

const getEndDate = () => {
    return axios.get(apilink + "/adherent/intervention/end-date")
        .then(response => response)
}

const getEtiquetteK = (data) => {
    return axios.post(apilink + "/adherent/intervention/etiquettek", data)
        .then(response => response.data)
}

const getEtatCivilClient = (adherent) => {
    return axios.get(apilink + "/adherent/intervention/etat-civil/" + adherent)
        .then(response => response.data)
}

const getTimer = () => {
    return axios.get(apilink + "/adherent/timer")
        .then(response => response.data)
}

const getTimer2 = () => {
    return axios.post(apilink + "/timer/startTimer/1000")
        .then(response => response.data)
}

const getCommuneByCP = (data) => {
    return axios.get(apilink + "/adherent/commune/" + data)
        .then(response => response.data)
}

const saveClient = (data) => {
    return axios.post(apilink + "/adherent/intervention/client/new", data)
        .then(response => response.data)
}

const egalPrecedentPression = (data) => {
    return axios.post(apilink + "/adherent/intervention/precedent-pression", data)
        .then(response => response.data)
}

const getDetailFixationAfficheur = (data) => {
    return axios.get(apilink + "/adherent/intervention/detail-fixation-afficheur/" + data)
        .then(response => response.data)
}

const getAllAdherentSaisieUsed = (pageNumber = 1, pageSize = 5) => {
    return axios.get(apilink + "/adherent/last-used?pageNumber=" + pageNumber + "&pageSize=" + pageSize)
        .then(response => response.data)
}

const checkStationnementAvailability = (data) => {
    return axios.post(apilink + "/adherent/intervention/stationnement/isavailable", data)
        .then(response => response.data)
}

const saveStationnement = (data) => {
    return axios.post(apilink + "/adherent/intervention/stationnement/new", data)
        .then(response => response.data)
}

const saveVehicule = (data) => {
    return axios.post(apilink + "/adherent/intervention/vehicule/new", data)
        .then(response => response.data)
}

const getAllMarqueVehicule = (data) => {
    return axios.get(apilink + "/adherent/marque/" + data)
        .then(response => response.data)
}

const getAllModeleVehicule = (adherent, marque) => {
    return axios.get(apilink + "/adherent/modele/" + adherent + "/" + marque)
        .then(response => response.data)
}

const getAllFabricant = () => {
    return axios.get(apilink + "/adherent/fabricants")
        .then(response => response.data)
}

const saveTaximetre = (data) => {
    return axios.post(apilink + "/adherent/intervention/taximetre/new", data)
        .then(response => response.data)
}

const savePhoto = (data) => {
    return axios.post(apilink + "/adherent/photo/add", data)
        .then(response => response.data)
}

const stopwatchStart = (data) => {
    return axios.post(apilink + "/adherent/stopwatch-start", data)
        .then(response => response.data)
}

const stopwatchStop = (data) => {
    return axios.post(apilink + "/adherent/stopwatch-stop", data)
        .then(response => response.data)
}

const stopwatchReset = (data) => {
    return axios.post(apilink + "/adherent/stopwatch-reset", data)
        .then(response => response.data)
}

const stopwatchElapsed = (data) => {
    return axios.get(apilink + "/adherent/stopwatch-elapsed", data)
        .then(response => response.data)
}

const getAllBrisScelles = () => {
    return axios.get(apilink + "/adherent/bris-scelles")
        .then(response => response.data)
}

const getAllD2Autres = () => {
    return axios.get(apilink + "/adherent/d2autres")
        .then(response => response.data)
}

const getArretePrefectoral = (clef_arrete_national, clef_dpt) => {
    return axios.get(apilink + "/adherent/arrete-prefectoral/" + clef_arrete_national + "/" + clef_dpt + "")
        .then(response => response.data)
}

const getAutreIntervention = (data) => {
    return axios.post(apilink + "/adherent/intervention/check-heure-vp", data)
        .then(response => response.data)
}

const checkInterventionVPAnnule = (data) => {
    return axios.post(apilink + "/adherent/intervention/check-vp-annule", data)
        .then(response => response.data)
}

const getQualificationAppareil = (data) => {
    return axios.post(apilink + "/adherent/intervention/qualification-appareil", data)
        .then(response => response.data)
}

const createIntervention = (data) => {
    return axios.post(apilink + "/adherent/intervention/new", data)
        .then(response => response.data)
}

const updateIntervention = (data) => {
    return axios.put(apilink + "/adherent/intervention", data)
        .then(response => response.data)
}

const updateClient = (data) => {
    return axios.put(apilink + "/adherent/intervention/client", data)
        .then(response => response.data)
}

const getStationnement = (data) => {
    return axios.post(apilink + "/adherent/stationnement", data)
        .then(response => response.data)
}

const getVehicule = (data) => {
    return axios.post(apilink + "/adherent/vehicule", data)
        .then(response => response.data)
}

const updateStationnement = (data) => {
    return axios.put(apilink + "/adherent/intervention/stationnement", data)
        .then(response => response.data)
}

const updateVehicule = (data) => {
    return axios.put(apilink + "/adherent/vehicule", data)
        .then(response => response.data)
}

const getTaximetre = (data) => {
    return axios.post(apilink + "/adherent/taximetre", data)
        .then(response => response.data)
}

const getInterventionExistante = (data) => {
    return axios.post(apilink + "/adherent/intervention/existante", data)
        .then(response => response.data)
}

const updateTaximetre = (data) => {
    return axios.put(apilink + "/adherent/intervention/taximetre", data)
        .then(response => response.data)
}

const testVehiculeIntervention = (data) => {
    return axios.post(apilink + "/adherent/vehicule/test-intervention", data)
        .then(response => response.data)
}

const getClientAutorisation = (data) => {
    return axios.post(apilink + "/adherent/client-autorisation", data)
        .then(response => response.data)
}

const getSignaturesLogiciel = (marque, modele) => {
    return axios.get(apilink + "/adherent/modeleslog/signature/" + marque + "/" + modele)
        .then(response => response.data)
}

const downloadReport = () => {
    return axios.get(apilink + "/adherent/report/tax_rapport")
        .then(response => response.data)
}

export const supervisionService = {
    downloadReport,
    getSignaturesLogiciel,
    testVehiculeIntervention,
    getClientAutorisation,
    createIntervention,
    getTaximetre,
    updateTaximetre,
    getInterventionExistante,
    updateIntervention,
    updateClient,
    updateStationnement,
    updateVehicule,
    getStationnement,
    getVehicule,
    checkInterventionVPAnnule,
    getQualificationAppareil,
    getAllD2Autres,
    getArretePrefectoral,
    getAutreIntervention,
    stopwatchStart,
    stopwatchStop,
    stopwatchReset,
    stopwatchElapsed,
    getAllBrisScelles,
    getAllAdherent,
    getAdherent,
    getAdherentByIdentification,
    getAllIntervenant,
    getIntervenant,
    getIntervenantQualif,
    getPreSaisieModel,
    getAllNumeroRapport,
    loginAdherent,
    initSupervision,
    getSupervisionDate,
    initPointControle,
    createGeneral,
    createPointControle,
    initLienRapport,
    getAllDetailDocByTypeDoc,
    getAllTypeDocByActivite,
    getAllDocumentsQualites,
    getDocumentListeByNumeroDocument,
    getDocumentListeByTypeAndDetailDoc,
    saveSupervision,
    saveSupervisionPartially,
    updateSupervision,
    updateSupervisionPartially,
    getTextes,
    getClients,
    getClient,
    getInterventions,
    lectureIntervention,
    getInfos,
    getInterventionTaximetre,
    getModelesLog,
    getModeleLog,
    getTypeTousAppareils,
    getVehiculeTaxPhotos,
    getAllArreteNational,
    getAllArreteNationalDpt,
    getVehiculeTaxMateriel,
    getMarqueAppareil,
    getModeleAppareil,
    getModeleTousAppareils,
    getAllCouleur,
    getAllMontageAutorise,
    saveSaisiePartially,
    saveSaisie,
    getAllDepartement,
    getAidePneuAvant,
    getAidePneuArriere,
    readDocument,
    controlePose,
    getCodePostalsByClefDpt,
    getAutorisationCouleurLum,
    getDepartement,
    getVehiculeTaximetreVignettes,
    getIntervention,
    egalePrecedentAutres,
    egalePrecedentPhotos,
    egalePrecedentTax,
    egalePrecedentVignette,
    getManometre,
    getPlanning,
    getStockVignetteAdherent,
    checkVignetteStockIntervention,
    getCodeTournant,
    verifyCodeDeblocage,
    rechargerVidePointControle,
    getFocusSupervision,
    isVignetteUnique,
    testReport,
    getInstrumentsDispos,
    getDetailFixation,
    getNumTaxSerieObligatoire,
    getDateEffectueeIntervention,
    getHistoriqueAfficheur,
    egalePrecedent2,
    getStationnements,
    getVehiculeStationnement,
    getArbreTaximetre,
    getArbreInterventions,
    getStartDate,
    getEndDate,
    getModeleTypeTax,
    getEtiquetteK,
    getTimer,
    getTimer2,
    getEtatCivilClient,
    getCommuneByCP,
    saveClient,
    saveStationnement,
    checkStationnementAvailability,
    egalPrecedentPression,
    getDetailFixationAfficheur,
    getInterventionsTachy,
    getAllAdherentSaisieUsed,
    getAllMarqueVehicule,
    getAllModeleVehicule,
    saveVehicule,
    getAllFabricant,
    saveTaximetre,
    savePhoto,
}

const handleResponse = (response) => {
    return response.text()
        .then(
            text => {
                const data = text
                if (!response.ok) {
                    if (response.status === 401 || response.status === 403) {
                        history.push('/logout')
                        // eslint-disable-next-line no-restricted-globals
                        location.reload(true)
                    }
                    const error = JSON.parse(data).message
                    return Promise.reject(error)
                }
                return data
            }
        )
}