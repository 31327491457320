import { useEffect, useState } from "react"
import { supervisionService } from "../../services/supervisionService"
import { formatDate } from "../../helpers/utils"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { toast } from "react-toastify"
import Skeleton from 'react-loading-skeleton'

const LastUsedSaisie = () => {

    const [allSaisieData, setAllSaisieData] = useState(null)
    const [loadingData, setLoadingData] = useState(null)
    const [totalRecords, setTotalRecords] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    // Animation
    const [rotate, setRotate] = useState(false)


    useEffect(() => {
        setLoadingData(true)
        supervisionService.getAllAdherentSaisieUsed()
            .then(response => {
                setAllSaisieData(response.data)
                setTotalRecords(response.totalRecords)
                setTotalPages(response.totalPages)
                setCurrentPage(response.pageNumber)
            })
            .catch(error => toast.error(error, { containerId: 'App' }))
            .finally(() => setLoadingData(false))
    }, [])

    const handleReload = (e) => {
        setLoadingData(true)
        setRotate(true)
        supervisionService.getAllAdherentSaisieUsed()
            .then(response => {
                setAllSaisieData(response.data)
                setTotalRecords(response.totalRecords)
                setTotalPages(response.totalPages)
                setCurrentPage(response.pageNumber)
                console.log(response)
            })
            .catch(error => toast.error(error, { containerId: 'App' }))
            .finally(() => setLoadingData(false))
    }

    const handleLoadMore = () => {
        if (currentPage < totalPages) {
            setIsLoadingMore(true)
            supervisionService.getAllAdherentSaisieUsed(currentPage + 1, undefined)
                .then(response => {
                    const newData = allSaisieData.concat(response.data)
                    setAllSaisieData(newData)
                    setCurrentPage(response.pageNumber)
                })
                .catch(error => toast.error(error, { containerId: 'App' }))
                .finally(() => setIsLoadingMore(false))
        }
    }

    return (
        <>
            <div className="last-used-section">
                <div className="lus-container">
                    <div className="lus-header">
                        <div className="lus-titles">
                            <h6>Saisie Taximètre</h6>
                            {(!loadingData &&
                                <div className="lus-sub_title">
                                    <span>{totalRecords} saisies au total</span>
                                </div>
                            ) ||
                                <Skeleton height={18} />
                            }
                        </div>
                        <div className="lus-actions">
                            <span
                                className={"lus-rotate-icon" + (rotate ? " lus-rotate-icon-animate" : "")}
                                onClick={handleReload}
                                onAnimationEnd={() => setRotate(false)}
                            >
                                <i class="fa-solid fa-rotate-right"></i>
                            </span>
                        </div>
                    </div>
                    <div className="lus-body">
                        <div className="dashboard-liste">
                            <table className="lus-table">
                                <thead className="lus-table-head">
                                    <tr>
                                        <th>
                                            <div>Adhérent</div>
                                        </th>
                                        <th>
                                            <div>Date</div>
                                        </th>
                                        <th>
                                            <div>Clients</div>
                                        </th>
                                        <th>
                                            <div>Rapport</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!loadingData && allSaisieData && allSaisieData.map((item, n) =>
                                        <tr key={n}>
                                            <td>
                                                <div>
                                                    <div>
                                                        <div className="lus-img">
                                                            <img src={`data:image/jpeg;base64,${item.logo_adherent}`} alt="Adhérent" />
                                                        </div>
                                                        <span className="lus-table-big_title">{item.identification_adherent}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span>{formatDate(item.appli_web_last_used)}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="lus-text">
                                                    <span>{item.nom_client}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="lus-text">
                                                    <span>{item.numero_oi === 0 || item.numero_oi === undefined || item.numero_oi === null ? "Interv. en cours" : item.numero_oi}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )) ||
                                        <>
                                            <tr>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                                <td>
                                                    <Skeleton height={24} />
                                                </td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {(!loadingData && currentPage < totalPages &&
                        <div className="lus-footer">
                            <div className="lus-footer-container">
                                <span className="load-more" onClick={handleLoadMore}>
                                    {isLoadingMore ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center' }} /> : "Afficher plus"}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )

}

export default LastUsedSaisie