import { Formik, Field, Form, ErrorMessage } from 'formik'
import { IoMdClose } from "react-icons/io"
import { useRef, useState } from 'react'
import { useEffect } from 'react'
import { supervisionService } from '../../../services/supervisionService'
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { toast } from 'react-toastify'
import Checkbox from '@mui/material/Checkbox'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { fr } from "date-fns/locale"
import SignaturePopup from '../../../components/Popup/SignaturePopup'
import { afficheCouleur, formatDate, lockBody, unlockBody } from '../../../helpers/utils'
import useConfirm from '../../../hooks/useConfirm'

const InterventionPopup = ({ newIntervention, intervention, taximetre, clientAutorisation, adherent, ...props }) => {

    const [saving, setSaving] = useState(false)
    const formikRef = useRef()
    const numero_client = parseInt(props.client.id.split("_")[1])
    const [showPopup, setShowPopup] = useState(false)
    const [brisScellements, setBrisScellements] = useState([])
    const [arretes, setArretes] = useState([])
    const [d2autres, setD2Autres] = useState([])
    const [intervenants, setIntervenants] = useState([])
    const [inputName, setInputName] = useState("")
    // const [clientAutorisation, setClientAutorisation] = useState(null)
    const { isConfirmedDeblocage } = useConfirm()

    useEffect(() => {
        // const request = {
        //     numero_adherent: adherent.numero_adherent,
        //     numero_client: numero_client
        // }
        // supervisionService.getClientAutorisation(request)
        //     .then(response => setClientAutorisation(response))
        //     .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
        supervisionService.getAllBrisScelles()
            .then(response => setBrisScellements(response))
            .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
        supervisionService.getAllArreteNational()
            .then(response => setArretes(response))
            .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
        supervisionService.getAllD2Autres()
            .then(response => setD2Autres(response))
            .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
        supervisionService.getAllIntervenant(adherent.numero_adherent, true)
            .then(response => setIntervenants(response))
            .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
    }, [])

    const handleSaveIntervention = e => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const haveErrors = async (data) => {
        let message = ""
        let error = true

        if (data.a1 === false && data.a2 === false && data.a3 === false && data.a4 === false
            && data.b1 === false && data.b2 === false && data.b3 === false && data.b4 === false && data.c1 === false && data.d1 === false && data.d2 === false) {
            message = "Vous devez au moins sélectionner un type d'intervention."
        }
        else if (data.c1 === false && data.b3 === true) {
            message = "Si b3 Vous devez cocher aussi le bris de scellement."
        }
        else if (data.c1 === true && data.clef_bris === -1) {
            message = "Vous devez préciser le bris de scellement."
        }
        else if (data.c1 === true && data.clef_bris === 1 && data.c_detail === "") {
            message = "Pour un bris de scellements 'autres', vous devez préciser le détail."
        }
        else if (data.d1 === false && data.b4 === true) {
            message = "Dans le cas d'un B4 vous devez obligatoirement cocher aussi D1 Changement de tarif."
        }
        else if (data.d1 === true && data.clef_arrete_national === -1) {
            message = "Vous devez préciser l'arrêté tarifaire du tarif."
        }
        else if (data.d1 === true && data.clef_arrete_national !== -1) {
            const response = await supervisionService.getArretePrefectoral(data.clef_arrete_national, props.stationnementNode.subid)
            if (response) {
                message = "Pas d'arrêté préfectoral renseigné pour le département."
            }
        }
        else if (data.d2 === true && data.clef_d2 === -1) {
            message = "Vous devez préciser le D2."
        }
        else if (data.numero_intervenant_prevu === -1) {
            message = "Vous devez préciser le technicien prévu pour s'assurer de sa qualification."
        }
        // Interdiction modification de date si-jour même
        else if (newIntervention === false && (intervention.b1 === true || intervention.b2 === true || intervention.b3 === true || intervention.b4 === true)) {
            if (intervention && intervention.date_intervention < Date.now()) {
                if (intervention.numero_oiso > 0) {
                    if (new Date(data.date_intervention) !== new Date(intervention.date_planification)) {
                        toast.error("Vous ne pouvez pas modifier une date de planification VP si planning OISO dépassé; vous devez supprimer l'intervention, envoyer un email à la DREETS puis replanifier.", { autoClose: 5000, containerId: "App" })
                        let show_modal_deblocage = true
                        while (show_modal_deblocage) {
                            try {
                                let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Modification plannif OISO VP date intervention dépassée.", rapport_numero: intervention.numero_oi_interne, option: 2, activite: 1 })

                                if (confirmCodeDeblocage === "code_bon") {
                                    show_modal_deblocage = false
                                    error = false
                                }
                                else if (confirmCodeDeblocage === "code_incorrect") {
                                    toast.error("Code de déblocage erroné.", { autoClose: 5000, containerId: "App" })
                                }
                                else {
                                    toast.error("Arrêt...", { autoClose: 5000, containerId: "App" })
                                    message = "Arrêt..."
                                    show_modal_deblocage = false
                                    return true
                                }
                            } catch (err) {
                                toast.error("Erreur de déblocage.", { autoClose: 5000, containerId: "App" });
                                show_modal_deblocage = false
                            }
                        }
                    }
                    else {
                        error = false
                    }
                }
                else {
                    error = false
                }
            }
            else {
                error = false
            }
        }
        else if (data.b1 === true || data.b2 === true || data.b3 === true || data.b4 === true) {
            if (new Date(data.date_intervention).getDay() === 0) {
                message = "Vous ne pouvez pas planifier une VP le Dimanche."
            }
            else if (new Date(data.date_intervention).getHours() < 6) {
                message = "Vous ne pouvez pas planifier une VP avant 6 heures du matin."
            }
            else if (new Date(data.date_intervention).getHours() > 20) {
                message = "Vous ne pouvez pas planifier une VP après 20 heures."
            }
            else {
                const autreInterventionRequest = {
                    numero_adherent: adherent.numero_adherent,
                    numero_client: newIntervention ? 0 : numero_client,
                    clef_stationnement: newIntervention ? 0 : parseInt(props.stationnementNode.id.split("_")[2]),
                    clef_lien: newIntervention ? 0 : intervention.clef_lien,
                    numero_intervention: newIntervention ? 0 : intervention.numero_intervention,
                    numero_intervenant_prevu: data.numero_intervenant_prevu,
                    date_intervention: data.date_intervention,
                }

                const vpMemeHeure = await supervisionService.getAutreIntervention(autreInterventionRequest)
                const vpAnnule = await supervisionService.checkInterventionVPAnnule(autreInterventionRequest)

                if (vpMemeHeure) {
                    message = "Il y a déjà une VP prévue à cette heure ci, vous devez la décaler (même de 10 minutes), OISO n'accepte pas 2 VP à la même heure"
                    return { error: error, message: message }
                }
                else if (vpAnnule) {
                    message = "Il y avait déjà une VP prévue à cette heure ci qui a été annulée, mais OISO ne sait pas le gérer... décalez l'heure de 1 minute"
                    return { error: error, message: message }
                }
                else {
                    error = false
                }
            }
        }
        else if (newIntervention === true && (data.a1 === true || data.a2 === true || data.a3 === true || data.a4 === true || data.a5 === true || data.a5_bis === true || data.c1 === true || data.d1 === true)) {
            let autoriser_installation = false
            const qualifRequest = {
                numero_adherent: adherent.numero_adherent,
                numero_intervenant: data.numero_intervenant_prevu,
                marque_appareil: taximetre.marque_taximetre,
                modele_appareil: taximetre.modele_taximetre,
            }
            const response = await supervisionService.getQualificationAppareil(qualifRequest)
            if (response) {
                autoriser_installation = true
            }
            if (autoriser_installation === false && adherent.absence_blocage === false) {
                message = "Impossible d'enregistrer la nouvelle intervention, car l'installateur sélectionné, n'est pas qualifié pour ce taximètre"
            }
        }
        else {
            error = false
        }
        return { error: error, message: message }
    }

    const saveIntervention = async (request) => {
        const _intervention = await supervisionService.createIntervention(request)
        setSaving(false)
        props.close(_intervention)
    }

    const updateIntervention = async (request) => {
        const _intervention = await supervisionService.updateIntervention(request)
        setSaving(false)
        props.close(_intervention)
    }

    // const handleChangeMarque = (e) => {
    //     const value = e.target.value
    //     formikRef.current.setFieldValue("marque_vehicule", value)
    //     if (value !== -1) {
    //         supervisionService.getAllModeleVehicule(adherent.numero_adherent, value)
    //             .then(response => setModeles(response))
    //             .catch(error => { toast.error(error, { containerId: 'App' }); formikRef.current.setFieldValue("modele_vehicule", -1) })
    //     }
    //     else {
    //         formikRef.current.setFieldValue("modele_vehicule", -1)
    //     }
    // }

    const togglePopup = (inputName) => {
        setInputName(inputName)
        setShowPopup(!showPopup)
        showPopup ? unlockBody() : lockBody()
    }

    const handleChangeCheckbox = (e) => {
        const name = e.target.name
        const value = e.target.checked

        if (name.startsWith("a")) {
            // Si la case est cochée, décochez toutes les autres cases 
            if (value) {
                const newValues = {}
                const aCheckboxes = ["a1", "a2", "a3", "a4", "a5", "b1", "b2", "b3", "b4", "c1", "d1"]

                aCheckboxes.forEach((checkbox) => {
                    newValues[checkbox] = false // Décochez toutes les cases "a"
                })

                // Cochez la case actuelle
                newValues[name] = true

                formikRef.current.setValues({
                    ...formikRef.current.values,
                    ...newValues,
                })
            } else {
                // Si on décoche, mettez simplement à jour la valeur
                formikRef.current.setFieldValue(name, value)
            }
        }
        else if (name === "d2") {
            // La logique pour "d2" reste la même, mais elle dépend de l'état des cases "a"
            // const aChecked = ["a1", "a2", "a3", "a4", "a5"].some(
            //     (checkbox) => formikRef.current.values[checkbox] === true
            // )

            formikRef.current.setFieldValue(name, value)

        }
        else if (value) {
            // Si une case est cochée, décochez toutes les autres
            const newValues = {}

            // Liste de toutes les cases (peut être ajustée selon vos besoins)
            const checkBoxes = ["a1", "a2", "a3", "a4", "a5"]

            checkBoxes.forEach((checkbox) => {
                newValues[checkbox] = false // Décochez toutes les cases
            })

            // Cochez la case actuelle
            newValues[name] = true

            formikRef.current.setValues({
                ...formikRef.current.values,
                ...newValues,
            })
        }
        else {
            formikRef.current.setFieldValue(name, value)
        }
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                        <span className="text-danger">{(intervention?.date_effectuee ? " - Changement impossible" : "")}</span>
                    </h3>
                    <div className="popup-close-button" onClick={e => props.close(null)}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body pt-0 popup-liaison-body">
                    <div className="form-saisie-container">
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize={false}
                            initialValues={{
                                numero_client: !newIntervention ? intervention.numero_client : numero_client,
                                a1: !newIntervention ? intervention.a1 : false,
                                a2: !newIntervention ? intervention.a2 : false,
                                a3: !newIntervention ? intervention.a3 : false,
                                a4: !newIntervention ? intervention.a4 : false,
                                a5: !newIntervention ? intervention.a5 : false,
                                a5_bis: !newIntervention ? intervention.a5_bis : false,
                                b1: !newIntervention ? intervention.b1 : false,
                                b2: !newIntervention ? intervention.b2 : false,
                                b3: !newIntervention ? intervention.b3 : false,
                                b4: !newIntervention ? intervention.b4 : false,
                                c1: !newIntervention ? intervention.c1 : false,
                                c2: !newIntervention ? intervention.c2 : false,
                                c_detail: !newIntervention ? intervention.c_detail : "",
                                clef_bris: !newIntervention ? intervention.clef_bris : -1,
                                d1: !newIntervention ? intervention.d1 : false,
                                clef_arrete_national: !newIntervention ? intervention.clef_arrete_national : -1,
                                d2: !newIntervention ? intervention.d2 : false,
                                clef_d2: !newIntervention ? intervention.clef_d2 : -1,
                                garantie: !newIntervention ? intervention.garantie : false,
                                d2_detail: !newIntervention ? intervention.d2_detail : "",
                                date_intervention: !newIntervention ? intervention.date_intervention : new Date(),
                                numero_oi_interne: !newIntervention ? intervention.numero_oi_interne : "",
                                temps_prevu_heure: !newIntervention ? intervention.temps_prevu_heure : 0,
                                numero_oiso: !newIntervention ? intervention.numero_oiso : "",
                                numero_intervenant_prevu: !newIntervention ? intervention.numero_intervenant_prevu : -1,
                                sign1: !newIntervention ? intervention.sign1 : "",
                                sign4: !newIntervention ? intervention.sign4 : "",
                            }}
                            validate={values => {
                                const errors = {}

                                // if (values.marque_vehicule === -1) {
                                //     errors.marque_vehicule = "Vous devez renseigner une marque."
                                // }
                                // if (values.modele_vehicule === -1) {
                                //     errors.modele_vehicule = "Vous devez renseigner un modele."
                                // }

                                return errors
                            }}
                            onSubmit={async (values, errors) => {
                                setSaving(true)
                                console.log("values", values)
                                let data = values
                                // data.numero_adherent = adherent.numero_adherent
                                // data.immatriculation_vehicule = data.immatriculation_vehicule.replace(" ", "")
                                // data.numero_serie = data.numero_serie.replace(" ", "")
                                // data.marque_vehicule = parseInt(data.marque_vehicule)
                                // data.modele_vehicule = parseInt(data.modele_vehicule)
                                const verification = await haveErrors(data)
                                const error = verification.error
                                const message = verification.message

                                if (error) {
                                    console.log("error")
                                    toast.error(message, { containerId: 'App' })
                                    setSaving(false)
                                    return
                                }
                                else {
                                    if (newIntervention) {
                                        data.numero_adherent = adherent.numero_adherent
                                        data.clef_stationnement = parseInt(props.selected.split("_")[2])
                                        data.clef_lien = props.selected.split("_")[0] === "t" || props.selected.split("_")[0] === "i" ? parseInt(props.selected.split("_")[4]) : undefined

                                        data.clef_arrete_national = data.clef_arrete_national === -1 ? 0 : data.clef_arrete_national
                                        data.clef_d2 = data.clef_d2 === -1 ? 0 : data.clef_d2
                                        data.clef_bris = data.clef_bris === -1 ? 0 : data.clef_bris
                                        data.numero_intervenant_prevu = data.numero_intervenant_prevu === -1 ? 0 : data.numero_intervenant_prevu
                                        data.numero_oi = 0
                                        data.numero_oi_interne = ""
                                        data.send_email_dreets = true
                                        saveIntervention(data)
                                    }
                                    else if (!newIntervention) {

                                        data.numero_adherent = adherent.numero_adherent
                                        data.clef_stationnement = parseInt(props.selected.split("_")[2])
                                        data.clef_lien = intervention.clef_lien

                                        data.clef_arrete_national = data.clef_arrete_national === -1 ? 0 : data.clef_arrete_national
                                        data.clef_d2 = data.clef_d2 === -1 ? 0 : data.clef_d2
                                        data.clef_bris = data.clef_bris === -1 ? 0 : data.clef_bris
                                        data.numero_intervenant_prevu = data.numero_intervenant_prevu === -1 ? 0 : data.numero_intervenant_prevu
                                        data.numero_intervention = intervention.numero_intervention
                                        data.date_effectuee = intervention.date_effectuee
                                        // data.numero_oi = 0
                                        // data.numero_oi_interne = ""
                                        data.send_email_dreets = true
                                        // saveIntervention(data)
                                        updateIntervention(data)
                                    }
                                }
                                setSaving(false)
                                // data.clef_stationnement = parseInt(props.selected.split("_")[2])
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    {/* A */}
                                    <div className='d-flex flex-row align-items-center border-bottom'>
                                        <h1 className='mr-5 intervention-title'>A</h1>
                                        <div className="form-group mb-0">
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="a1"
                                                            value={values.a1}
                                                            checked={values.a1}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={intervention?.date_effectuee ? true : false}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            1 - Montage Tax Neuf
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="a2"
                                                            value={values.a2}
                                                            checked={values.a2}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={intervention?.date_effectuee ? true : false}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            2 - Démontage / remontage
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="a3"
                                                            value={values.a3}
                                                            checked={values.a3}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={intervention?.date_effectuee ? true : false}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            3 - Repose Taximètre réparé
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="a4"
                                                            value={values.a4}
                                                            checked={values.a4}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={intervention?.date_effectuee ? true : false}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            4 - Pose Relais
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* B */}
                                    <div className='d-flex flex-row align-items-center border-bottom'>
                                        <h1 className='mr-5 intervention-title'>B</h1>
                                        <div className="form-group mb-0">
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            disabled
                                                            name="b1"
                                                            value={values.b1}
                                                            checked={values.b1}
                                                            onChange={handleChangeCheckbox}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            1 - VP suite vignette rouge
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="b2"
                                                            value={values.b2}
                                                            checked={values.b2}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={intervention?.date_effectuee ? true : false}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            2 - VP annuelle
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            disabled
                                                            name="b3"
                                                            value={values.b3}
                                                            checked={values.b3}
                                                            onChange={handleChangeCheckbox}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            3 - VP suite intervention sur panne
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            disabled
                                                            name="b4"
                                                            value={values.b4}
                                                            checked={values.b4}
                                                            onChange={handleChangeCheckbox}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            4 - VP suite changement de tarif
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* C */}
                                    <div className='d-flex flex-row align-items-center w-100 border-bottom'>
                                        <h1 className='mr-5 intervention-title'>C</h1>
                                        <div className="form-group mb-0">
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="c1"
                                                            value={values.c1}
                                                            checked={values.c1}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={intervention?.date_effectuee ? true : false}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            1 - Bris scellement (sans dépose)
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row align-items-center">
                                                    <div className="col">
                                                        <div className="form-group mb-0">
                                                            <Field className="form-control" type="text" name="c_detail" disabled={intervention?.date_effectuee ? true : false} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <Field name="clef_bris" className="form-control w-100" as="select" disabled={intervention?.date_effectuee ? true : false} >
                                                        <option value="-1"></option>
                                                        {brisScellements?.map((item, index) => (
                                                            <option key={index} value={item.clef_bris}>{item.texte}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="scellement" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* D */}
                                    <div className='d-flex flex-row align-items-center w-100 pb-3'>
                                        <h1 className='mr-5 intervention-title'>D</h1>
                                        <div className="form-group mb-0">
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="d1"
                                                            value={values.d1}
                                                            checked={values.d1}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={intervention?.date_effectuee ? true : false}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            1 - MAJ Tarif/admin :
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row align-items-center">
                                                    <div className="col">
                                                        <div className="form-group mb-0">
                                                            <Field name="clef_arrete_national" className="form-control w-100" as="select" disabled={intervention?.date_effectuee ? true : false}>
                                                                <option value="-1"></option>
                                                                {arretes?.map((item, index) => (
                                                                    <option key={index} value={item.clef_arrete_national}>{item.lettre + "  " + afficheCouleur(item.couleur_lettre).nom + " " + item.nom_arrete_national}</option>
                                                                ))}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="d2"
                                                            value={values.d2}
                                                            checked={values.d2}
                                                            onChange={handleChangeCheckbox}
                                                            disabled={intervention?.date_effectuee ? true : false}
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                                },
                                                            }}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            2 - Autre :
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row align-items-center">
                                                    <div className="col">
                                                        <div className="form-group mb-0">
                                                            <Field name="clef_d2" className="form-control w-100" as="select" disabled={intervention?.date_effectuee ? true : false}>
                                                                <option value="-1"></option>
                                                                {d2autres?.map((item, index) => (
                                                                    <option key={index} value={item.clef_d2}>{item.texte}</option>
                                                                ))}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row m-m-0">
                                        <div className="input-item flex align-items-center justify-content-center">
                                            <span className='mr-2'>GARANTIE</span>
                                            <Checkbox
                                                name="garantie"
                                                value={values.garantie}
                                                checked={values.garantie}
                                                onChange={e => setFieldValue('garantie', !values.garantie)}
                                                disabled={intervention?.date_effectuee ? true : false}
                                                sx={{
                                                    '&.Mui-disabled': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this color as needed
                                                    },
                                                }}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox'
                                                }} />
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <div className="form-group mb-0">
                                                    <Field className="form-control" type="text" name="d2_detail" disabled={intervention?.date_effectuee ? true : false} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Infos */}
                                    <div className="form-row pt-2">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Date prévue</div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils} className="m-w-100">
                                                        <KeyboardDateTimePicker
                                                            id="date-picker-dialog"
                                                            label="Date prévue"
                                                            inputVariant="outlined"
                                                            format="dd/MM/yyyy HH:mm"
                                                            disabled={intervention?.date_effectuee ? true : false}
                                                            value={values.date_intervention}
                                                            onChange={value => setFieldValue("date_intervention", value)}
                                                            ampm={false}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">N° OI Interne</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group mb-0">
                                                    <Field className="form-control" type="text" name="numero_oi_interne" disabled={intervention?.date_effectuee ? true : false} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Temps estimé (heure) :</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group mb-0">
                                                    <Field className="form-control" type="text" name="temps_prevu_heure" disabled={intervention?.date_effectuee ? true : false} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">OISO :</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group mb-0">
                                                    <Field className="form-control input-small" type="text" name="numero_oiso" disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">1ère VP disponible :</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group mb-0">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <button
                                                                type="button"
                                                                className='btn comet-btn-primary'
                                                                onClick={() => setFieldValue("date_intervention", new Date())}
                                                                disabled={intervention?.date_effectuee ? true : false}
                                                            >
                                                                {formatDate(new Date())}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row d-block">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Intervenant</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <Field name="numero_intervenant_prevu" className="form-control w-100" as="select" disabled={intervention?.date_effectuee ? true : false} >
                                                        <option value="-1"></option>
                                                        {intervenants?.map((item, index) => (
                                                            <option key={index} value={item.numero_intervenant}>{item.prenom + " " + item.nom}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="numero_intervenant_prevu" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!newIntervention &&
                                        <div className="form-row">
                                            <div className='mr-4'>
                                                <div className="form-row m-m-0">
                                                    <div className="modele-actions mb-3">
                                                        <button type="button" className={"modele-btn" + (values.sign1 ? " bg-success" : " bg-danger")} onClick={() => togglePopup("sign1")}>
                                                            Signature client avant
                                                            <i className="fas fa-external-link-alt ml-2"></i>
                                                        </button>
                                                        <span className='bg-success text-white'>{clientAutorisation && new Date(clientAutorisation) >= new Date() ? "(non obligatoire)" : ""}</span>
                                                        <span className={"details-item" + (values.sign1 ? " option-yes" : "")}>{!values.sign1 ? "Vide" : "Signé"}</span>
                                                    </div>
                                                </div>
                                                {values.sign1 ?
                                                    <div className="form-row m-m-0">
                                                        <div className="signature-img-container">
                                                            <img className='w-100 h-100 contain' src={"data:image/png;base64," + values.sign1} alt="" />
                                                        </div>
                                                    </div> : null
                                                }
                                                {showPopup && inputName === "sign1" &&
                                                    <SignaturePopup close={() => togglePopup("")} title="Signature Client avant" subtitle={"Merci de valider votre accord pour l'intervention : "} setFieldValue={setFieldValue} inputName={inputName} />
                                                }
                                            </div>
                                            <div>
                                                <div className="form-row m-m-0">
                                                    <div className="modele-actions mb-3">
                                                        <button type="button" className={"modele-btn" + (values.sign4 ? " bg-success" : " bg-danger")} onClick={() => togglePopup("sign4")}>
                                                            Signature client après
                                                            <i className="fas fa-external-link-alt ml-2"></i>
                                                        </button>
                                                        <span className={"details-item" + (values.sign4 ? " option-yes" : "")}>{!values.sign4 ? "Vide" : "Signé"}</span>
                                                    </div>
                                                </div>
                                                {values.sign4 ?
                                                    <div className="form-row m-m-0">
                                                        <div className="signature-img-container">
                                                            <img className='w-100 h-100 contain' src={"data:image/png;base64," + values.sign4} alt="" />
                                                        </div>
                                                    </div> : null
                                                }
                                                {showPopup && inputName === "sign4" &&
                                                    <SignaturePopup close={() => togglePopup("")} title="Signature Client avant" subtitle={"Merci de valider votre accord pour l'intervention : "} setFieldValue={setFieldValue} inputName={inputName} />
                                                }
                                            </div>
                                        </div>
                                    }
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div >
                <div className="popup-actions popup-actions-liaisons">
                    <div className="popup-action-open">
                        {formikRef.current && (formikRef.current.values.sign1 || (clientAutorisation && new Date(clientAutorisation) >= new Date())) &&
                            <button disabled={saving} type="button" className={"upload-file-btn cbtn-edit mr-2" + (saving ? " disabled" : "")} onClick={() => { props.close(null); props.startSaisie() }}>
                                <span> {saving ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Saisie"}</span>
                            </button>
                        }
                        <button disabled={saving} type="button" className={"upload-file-btn" + (saving ? " disabled" : "")} onClick={handleSaveIntervention}>
                            <span> {saving ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Enregistrer"}</span>
                        </button>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default InterventionPopup