import React, { useState, useEffect } from "react"
import { Formik, Form, FastField, ErrorMessage } from "formik"
import { Accordion, Card } from 'react-bootstrap'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { supervisionService } from "../../services/supervisionService"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"

// Utilisation de AutoSubmit pour la partie Point de controle
const PointControle = (props) => {

    const useStyles = makeStyles({
        table: {
            minWidth: 0
        }
    })
    const classes = useStyles()
    const [initial, setInitial] = useState({})
    const [isOkList, setIsOkList] = useState([])
    const [loadingAllOk, setLoadingAllOk] = useState(false)
    const [loadingRechargerVide, setLoadingRechargerVide] = useState(false)

    useEffect(() => {
        // code to run on component mount
        if (props.state) {
            const pointcontrole = [...props.state]
            for (let i = 0; i < pointcontrole.length; i++) {
                controleIsOkList(pointcontrole[i])
            }
            setInitial((recent) => {
                let data = {
                    ...recent,
                    pointcontrole: pointcontrole
                }
                props.passFormToParent(data)
                return data
            })
        }
        process.env.NODE_ENV === "development" && console.log(props.state)
    }, [props.state])

    const setAllOk = (e, values, setFieldValue) => {
        const pointcontrole = [...values.pointcontrole]
        const _isOkList = isOkList
        setLoadingAllOk(true)

        for (let i = 0; i < pointcontrole.length; i++) {
            for (let j = 0; j < pointcontrole[i].details.length; j++) {
                //setFieldValue('pointcontrole[' + i + '].details[' + j + '].resultat_sup', 1)
                pointcontrole[i].details[j].resultat_sup = 1
            }
            _isOkList.push(pointcontrole[i].titre_general)
        }
        setIsOkList(_isOkList)

        setInitial((recent) => {
            return {
                ...recent,
                pointcontrole
            }
        })
        props.passFormToParent({ pointcontrole })
        setLoadingAllOk(false)
    }

    const handleRechargerVide = () => {
        const data = props.presaisie
        data.sans_historique = true
        data.supervision = 0
        setLoadingRechargerVide(true)
        supervisionService.rechargerVidePointControle(data)
            .then(response => {

                const pointcontrole = [...response]

                for (let i = 0; i < pointcontrole.length; i++) {
                    for (let j = 0; j < pointcontrole[i].details.length; j++) {
                        //setFieldValue('pointcontrole[' + i + '].details[' + j + '].resultat_sup', 1)
                        pointcontrole[i].details[j].resultat_sup = -1
                    }
                }
                setIsOkList([])

                setInitial((recent) => {
                    return {
                        ...recent,
                        pointcontrole: pointcontrole
                    }
                })
                localStorage.setItem("pointcontrole", JSON.stringify(response))
                props.passFormToParent({ pointcontrole })
                setLoadingRechargerVide(false)
            })
            .catch(error => setLoadingRechargerVide(false))
    }

    const handleChangeCB = (e, setFieldValue, i, j, result, pointcontrole) => {
        setFieldValue('pointcontrole[' + i + '].details[' + j + '].resultat_sup', result)
        pointcontrole.details[j].resultat_sup = result
        controleIsOkList(pointcontrole)
    }

    const controleIsOkList = (pointcontrole) => {
        let _okList = isOkList
        let count = 0
        for (var i = 0; i < pointcontrole.details.length; i++) {
            if (pointcontrole.details[i].resultat_sup === 1 || pointcontrole.details[i].resultat_sup === 2 || pointcontrole.details[i].resultat_sup === 4) {
                count += 1
            }
        }
        if (count === pointcontrole.details.length) {
            if (_okList.indexOf(pointcontrole.titre_general) === -1) {
                _okList.push(pointcontrole.titre_general)
                setIsOkList(_okList)
            }
        }
        else {
            _okList = _okList.filter(name => name !== pointcontrole.titre_general)
            setIsOkList(_okList)
        }
    }

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initial !== {}
                    ? initial
                    : ''}
                validateOnBlur={true}
                validateOnChange={false}
                validate={(values) => {
                    const errors = {}
                    props.passFormToParent(values, errors)
                    localStorage.setItem("pointcontrole", JSON.stringify(values.pointcontrole))
                }}>
                {({ isSubmitting, values, setFieldValue, handleBlur }) => (
                    <>
                        <div className="controle-header-buttons">
                            <button className="button-recharger-vide cbtn cbtn-primary-two" onClick={e => handleRechargerVide()}>
                                {loadingRechargerVide ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Recharger vide"}
                            </button>
                            <button className="button-all-ok cbtn cbtn-success" onClick={e => setAllOk(e, values, setFieldValue)}>
                                {loadingAllOk ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Tout ok"}
                            </button>
                        </div>
                        <div className="infos-controle">
                            <button type="button" className="btn action-button infos-button infos-context">
                                <i className="fas fa-info"></i>
                                <span className="infos-context-text">
                                    {Object.keys(initial).length > 0 && (isOkList.length + " / " + initial.pointcontrole.length + " points remplis")}
                                </span>
                            </button>
                        </div>
                        <Form className="c-mt-6">
                            <div className="form-group">
                                <Accordion>
                                    {values && values.pointcontrole && values.pointcontrole.map((sup, i) =>
                                        <Card key={i}>
                                            <Accordion.Toggle
                                                as={Card.Header}
                                                className={"comet-collapse" + (isOkList.indexOf(sup.titre_general) !== -1 ? " isok" : "")}
                                                eventKey={"" + i + ""}>
                                                <label>{sup.titre_general}</label>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={"" + i + ""}>
                                                <Card.Body className={isOkList.indexOf(sup.titre_general) !== -1 ? "isok" : undefined}>
                                                    {sup.details.map((typesup, j) =>
                                                        <div key={j}>
                                                            {/* ------------ Commentaires soldes rq ---------- */}
                                                            <div className="form-group other-controle">
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <label>{typesup.titre_supervision}</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <label>Commentaires</label>
                                                                        <FastField
                                                                            name={"pointcontrole[" + i + "].details[" + j + "].commentaires"}
                                                                            as="textarea"
                                                                            className="form-control textarea-controle"
                                                                            onChange={e => setFieldValue('pointcontrole[' + i + '].details[' + j + '].commentaires', e.target.value)}
                                                                        />
                                                                        <ErrorMessage
                                                                            name={"commentaires_" + i + "" + j}
                                                                            component="div"
                                                                            className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {typesup.commentaires2 && typesup.commentaires2 !== "" &&
                                                                <div className="form-group n-1-detail">
                                                                    <div className="row">
                                                                        <div className="col-4">
                                                                            <label>n-1 détail</label>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <label>{typesup.commentaires2}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <FormControl component="fieldset">
                                                                <TableContainer component={Paper}>
                                                                    <Table className={classes.table} aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="center">SO</TableCell>
                                                                                <TableCell align="center">Ok</TableCell>
                                                                                <TableCell align="center">Rev</TableCell>
                                                                                <TableCell align="center">Mvs</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell align="center">
                                                                                    <Checkbox
                                                                                        name={"pointcontrole[" + i + "].details[" + j + "].resultat_sup"}
                                                                                        checked={typesup.resultat_sup === 0
                                                                                            ? true
                                                                                            : false}
                                                                                        onChange={e => typesup.resultat_sup === 0 ?
                                                                                            handleChangeCB(e, setFieldValue, i, j, -1, sup) :
                                                                                            handleChangeCB(e, setFieldValue, i, j, 0, sup)}
                                                                                        onBlur={handleBlur}
                                                                                        inputProps={{
                                                                                            'aria-label': 'primary checkbox'
                                                                                        }} />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Checkbox
                                                                                        name={"pointcontrole[" + i + "].details[" + j + "].resultat_sup"}
                                                                                        checked={typesup.resultat_sup === 1
                                                                                            ? true
                                                                                            : false}
                                                                                        onChange={e => typesup.resultat_sup === 1 ?
                                                                                            handleChangeCB(e, setFieldValue, i, j, -1, sup) :
                                                                                            handleChangeCB(e, setFieldValue, i, j, 1, sup)}
                                                                                        onBlur={handleBlur}
                                                                                        inputProps={{
                                                                                            'aria-label': 'primary checkbox'
                                                                                        }} />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Checkbox
                                                                                        name={"pointcontrole[" + i + "].details[" + j + "].resultat_sup"}
                                                                                        checked={typesup.resultat_sup === 2
                                                                                            ? true
                                                                                            : false}
                                                                                        onChange={e => typesup.resultat_sup === 2 ?
                                                                                            handleChangeCB(e, setFieldValue, i, j, -1, sup) :
                                                                                            handleChangeCB(e, setFieldValue, i, j, 2, sup)}
                                                                                        onBlur={handleBlur}
                                                                                        inputProps={{
                                                                                            'aria-label': 'primary checkbox'
                                                                                        }} />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Checkbox
                                                                                        name={"pointcontrole[" + i + "].details[" + j + "].resultat_sup"}
                                                                                        checked={typesup.resultat_sup === 3 || typesup.resultat_sup === 4
                                                                                            ? true
                                                                                            : false}
                                                                                        onChange={e => typesup.resultat_sup === 4 ?
                                                                                            handleChangeCB(e, setFieldValue, i, j, -1, sup) :
                                                                                            handleChangeCB(e, setFieldValue, i, j, 4, sup)}
                                                                                        onBlur={handleBlur}
                                                                                        inputProps={{
                                                                                            'aria-label': 'primary checkbox'
                                                                                        }} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </FormControl>
                                                        </div>
                                                    )}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    )}
                                </Accordion>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </div>
    )
}

export default PointControle